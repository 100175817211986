import { Box, Button, IconButton, InputAdornment } from "@mui/material";
import InputMask from "react-input-mask";

import { useFormik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { Visibility, VisibilityOff, Warning } from "@material-ui/icons";
import { locations, resgionList } from "../../../utils";
import CustomTextField from "../../Components/CustomTextField";
import TopHeading from "../../Components/TopHeading";
import Loading from "../../../Components/Loading/Loading";
import CustomLabel from "../../Components/customLabel";
import CustomSelect from "../../Components/CustomSelect";

interface OrgFormProps {
  handleOrgFormSubmit: (value: any) => void;
  isLoading: boolean;
  isChanged?: boolean;
  formValues: any;
}

const OrgForm: React.FC<OrgFormProps> = ({
  handleOrgFormSubmit,
  isLoading,
  isChanged,
  formValues,
}) => {
  const [formData, setFormData] = useState<any>({
    region: formValues?.region || "select_region",
    location:
      formValues?.location || (locations as any)[formValues?.region] || "",
    clientId: formValues?.clientId,
    secret: formValues?.secret,
  });

  const [visibleEye, setVisibleEye] = useState(false);

  const validationSchema = yup.object({
    region: yup
      .string()
      .test("region", "required", (value) => value !== "select_region")
      .required("required"),
    location: yup.string(),
    clientId: yup
      .string()
      .matches(/^[a-zA-Z0-9-]+$/, "invalid client_id format")
      .required("required"),
    secret: yup
      .string()
      .matches(/^[A-Za-z0-9-]+$/, "invalid secret format")
      .required("required"),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleOrgFormSubmit({
        ...values,
        location: (locations as any)[values.region],
      });
    },
  });

  //   fort secret
  //   ****************************************************************
  const renderInout = (field: any) => (
    <CustomTextField
      errorMessage={
        formik?.touched?.clientId && (formik?.errors?.clientId as any)
      }
      props={{
        ...field,
        fullWidth: true,
        inputProps: {
          maxLength: 38,
        },
        name: "clientId",
        onChange: formik.handleChange,
        error: formik.touched.clientId && Boolean(formik.errors.clientId),
        helperText: formik.touched.clientId && (
          <Warning sx={{ fontSize: 20, mt: "4px" }} className="error_icon" />
        ),
      }}
    />
  );

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    formik.handleChange(e);
  };
  const { region, clientId, secret } = formik.values;
  const isDisabled = region === "select_region" || !clientId || !secret;

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <TopHeading title="Select the source Genesys Cloud org" />
      <Box sx={{ padding: "24px 40px" }} height={isChanged ? "440px" : "454px"}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <CustomLabel
              label="Enter details about your Genesys Cloud organization here. The Client ID and
                    Secret can be obtained from your Genesys Cloud Admin portal."
              mb="24px"
            />
            <Box width={"100%"} display={"flex"}>
              <Box width={"25%"}>
                <CustomLabel
                  label="Region:"
                  mb="8px"
                  height={28}
                  display="flex"
                  alignItems="center"
                />
                <CustomLabel
                  label="Location:"
                  mb="8px"
                  height={28}
                  display="flex"
                  alignItems="center"
                />
                <CustomLabel
                  label="Client ID:"
                  mb="8px"
                  height={28}
                  display="flex"
                  alignItems="center"
                />
                <CustomLabel
                  label="Secret:"
                  mb="8px"
                  height={28}
                  display="flex"
                  alignItems="center"
                />
              </Box>
              <Box width={"75%"}>
                <Box mb={"8px"} width={270}>
                  <CustomSelect
                    props={{
                      name: "region",
                      inputProps: { maxLength: 10 },
                      value: formik.values.region,
                      onChange: handleChange,
                      error:
                        formik.touched.region && Boolean(formik.errors.region),
                      helperText: formik.touched.region && (
                        <Warning
                          sx={{ fontSize: 20, mt: "4px" }}
                          className="error_icon"
                        />
                      ),
                    }}
                    options={resgionList}
                  />
                </Box>
                <Box mb={"8px"} width={270}>
                  <CustomTextField
                    errorMessage={
                      formik.touched.location && (formik.errors.location as any)
                    }
                    props={{
                      fullWidth: true,
                      disabled: true,
                      placeholder: "euw2.pure.cloud",
                      name: "location",
                      inputProps: { maxLength: 16 },
                      value:
                        (locations as any)[formik.values.region] ||
                        formik.values.location,
                      onChange: handleChange,
                      error:
                        formik.touched.location &&
                        Boolean(formik.errors.location),
                    }}
                  />
                </Box>
                <Box mb={"8px"} width={396}>
                  <InputMask
                    mask="********-****-****-****-************"
                    placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxxx"
                    maskChar={null}
                    value={formik.values.clientId}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    children={renderInout as any}
                  />
                </Box>
                <Box mb={"8px"} width={396}>
                  <CustomTextField
                    errorMessage={
                      formik.touched.secret && (formik.errors.secret as any)
                    }
                    props={{
                      sx: { paddingRight: 0 },
                      name: "secret",
                      type: visibleEye ? "text" : "password",
                      fullWidth: true,
                      placeholder:
                        "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              // disabled={Boolean(formik.errors.secret)}
                              size="small"
                              onClick={() => setVisibleEye(!visibleEye)}
                              sx={{ p: 0, mr: "-3px" }}
                            >
                              {visibleEye ? (
                                <Visibility
                                  sx={{ fontSize: 16, color: "#414b55" }}
                                />
                              ) : (
                                <VisibilityOff
                                  sx={{ fontSize: 16, color: "#414b55" }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      },
                      inputProps: {
                        maxLength: 44,
                      },

                      value: formik.values.secret,
                      onChange: handleChange,
                      error:
                        formik.touched.secret && Boolean(formik.errors.secret),
                      helperText: formik.touched.secret && (
                        <Warning
                          sx={{ fontSize: 20, mt: "4px" }}
                          className="error_icon"
                        />
                      ),
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
      {/* bottom buttons */}
      <Box
        height={29}
        sx={{
          borderTop: "1px solid #c6d4e080",
          padding: "9px 16px 9px 0",
          boxSizing: "content-box",
        }}
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Box
          display="flex"
          justifyContent={"end"}
          sx={{
            "& .Mui-disabled": {
              borderColor: "#0000001f !important",
            },
          }}
        >
          <Button
            variant="contained"
            sx={{
              background: "#2993D1",
              border: "1px solid #2993D1",
              color: "#fff",
              fontFamily: "Verdana,Arial,sans-serif",
              fontSize: "13px",
              padding: "0px 13px",
              borderRadius: "100px",
              textTransform: "none",
              minWidth: "92px",
              marginRight: "8px",
              width: 120,
              "&:hover": {
                borderColor: "#2993D1",
                backgroundColor: "#2993D1",
              },
            }}
            type="submit"
            disabled={isLoading || isDisabled}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OrgForm;
